'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    body: 'Vul hieronder je e-mailadres in.',
    inputPlaceholder: 'E-mailadres',
    submit: 'Wachtwoord reset',
    title: 'Wachtwoord vergeten'
};