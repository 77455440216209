"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    barColor: '#000000',
    barHoverColor: '#f7e874',
    lineColor: '#c9c9c9',
    axisLabelColor: 'black',
    legendaInformation: '',
    dataProviderUrl: 'https://energymarketprice.com',
};
