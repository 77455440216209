"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        --button-component--border-radius: 27px;
        --button-component--font-color: #000;
        --button-component--background: var(--primary-logo-color);
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
