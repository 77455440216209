'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    menuPositionVariant: 1,
    menuSelected: 1,
    submitButtonText: 'Volgende stap',
    vendor: 'INretail Energie',
    smartMeterChoiceNoSmartMeterAndDontWantDisabled: true
};