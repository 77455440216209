'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    body: 'Log in om toegang te krijgen tot de klantomgeving van INretail Energie.',
    emailPlaceholder: 'naam@domein.nl',
    forgotPassword: 'Wachtwoord vergeten',
    newCustomer: 'Nog geen klant? Bereken je energievoordeel',
    passwordPlaceholder: 'Wachtwoord',
    submit: 'Inloggen',
    title: 'Welkom bij INretail Energie'
};