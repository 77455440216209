'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    /**
     * Generates bottom switch elements
     */
    bottomSwitches: {
        /**
         * Create bottom switch with name: 'showModule'
         */
        showModule: {
            /**
             * Default switch state is false
             */
            default: false,

            /**
             * The string to display when off
             */
            off: 'Bekijk modelcontract',

            /**
             * The string to display when on
             */
            on: 'Verberg modelcontract',

            /**
             * Switch variants
             * 1. Toggle switch (default)
             * 2. Toggle button
             */
            variant: 2
        }
    },

    /**
     * Generates top anchors for scrollable contracts layout
     * Mobile only option
     */
    topAnchors: {
        /**
         * Default enabled state is false
         */
        enabled: true,
        maxWidth: 822,
        /**
         * Anchors settings
         */
        settings: {
            combineProductGroups: false
        }
    },

    /**
     * Style injector for product panels
     * @param {Number} productSets the amount of product sets on screen
     * @param {Number} configurations the amount of configurations on screen
     * @param {Number} thisConfigurations the amount of options within the product panel for which to generate the style
     * @return {String}
     */
    panelStyling: (productSets, configurations, thisConfigurations) => `flex-basis: ${(configurations <= 7 ? 100 - configurations * 14 : 0) / productSets + thisConfigurations * 14}%;`,
    // Example (2, 4, 3) => 'flex-basis: 64%;'
    // Example (2, 4, 1) => 'flex-basis: 36%;'

    /**
     * Set filter based on product names
     * @param {Object} switches
     * @return {Array}
     */
    productSetFilterByName: ({ showGreen, showModule }) => {
        const returnable = [];

        if (!showModule) {
            returnable.push('model');
        }

        if (!showGreen) {
            returnable.push('groen');
        }

        return returnable;
    },
    // Example ({showModule: false}) => ['model'] hides all products with 'model' in the name

    /**
     * Transform function for product panels
     * @param {Array} set the products as an array
     * @return {Array}
     */
    productSetTransform: set => set.reverse(),
    // Example ([p0, p1, p2]) => [p2, p1, p0]

    /**
     * The selection details to show under the product, the order and the options
     */
    selectionDetails: [{
        allowEditMeterCapacity: true,
        title: 'Stroom',
        type: 'ELECTRICITY',
        valueTransform: value => `${value} kWh per jaar`
    }, {
        allowEditMeterCapacity: true,
        title: 'Gas',
        type: 'GAS',
        valueTransform: value => `${value} m³ per jaar`
    }, {
        title: 'Teruglevering',
        type: 'ELECTRICITY_PRODUCTION',
        valueTransform: value => `${value} kWh per jaar`
    }, {
        title: 'Leveringsadres',
        type: 'ADDRESS'
    }, {
        title: 'Energiecollectief',
        type: 'COLLECTIVE'
    }],

    /**
     * The string to display above the information specified by the user
     */
    specificationString: 'Specificaties van jouw energieproduct',

    /**
     * Generates top switch elements
     */
    topSwitches: {
        // /**
        //  * Create bottom switch with name: 'showModule'
        //  */
        // showGreen: {
        //     /**
        //      * Default switch state is false
        //      */
        //     default: false,
        //     /**
        //      * The string to display when off
        //      */
        //     off: 'Groene Europese wind en gas',
        //     /**
        //      * The string to display when on
        //      */
        //     on: 'Groene energie van Nederlandse bodem',
        //     /**
        //      * Switch variants
        //      * 1. Toggle switch (default)
        //      * 2. Toggle button
        //      */
        //     variant: 1,
        // },
    },

    /**
     * Variants
     * 1. Products in panels with options inside
     * 2. Options in panels with toggle to switch between product sets
     */
    variant: 1
};