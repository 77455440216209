'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    welcomeVariant: 2,
    faqURL: 'https://www.energieviainretail.nl/',
    priceCapURL: 'https://support.energyzero.nl/hc/nl/articles/7732004221341-Prijsplafond-2023'
};