'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    appTitle: 'INretail Energie',
    logoRedirect: 'https://energie.energieviainretail.nl',
    electricityAccentColor: '#32cd32',
    electricityProductionAccentColor: '#ffc107',
    gasAccentColor: '#3cc6e5',
    savingsAccentColor: '#f7e874',
    energyCostAccentColor: '#f7e874',
    subtitle: 'Versla de markt!',
    logoAppLink: '', // Je kunt hier een redirect invullen. Die is dan voor geauthenticeerde, als niet geauthenticeerde users.
    phoneNumber: '085 016 1811',
    appDomain: 'INretail Energie',
    vendorID: '1bc52675-8567-48a5-9e2b-be213e4961b7',
    smartCharging: true
};